import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w02TA04Barsch = () => (
  <Layout>
    <SEO title="w02TA04Barsch" />

    <h1 id="ueberschrift">Tiere im Alltag</h1>
    <h2>Achtung: Barsch! &emsp;  ©&nbsp;2001</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w02TA04Barsch&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p className="poem">
        Ein Barsch ist, wie der Name sagt,<br />
        stachelig, spitz und ziemlich barsch.<br />
        Ihm ist egal, was er annagt,<br />
        wenn keiner stoppt den Siegesmarsch.
        <div style={{fontSize: "0.75em"}}>BC &emsp; ©&nbsp;2001</div>
      </p>

    <p>---</p>

      <p>  
        Das neue Aquarium wurde liebevoll eingerichtet. Die Pflanzen und Fische scheinen sich wohl zu fühlen ...
        und dann stellt sich heraus, dass "etwas" anders läuft, als geplant. Pflanzen gehen ein, Fische verschwinden.
        Eine Ursache kann sein, dass Sie einen Barsch(artigen) im Becken haben.
        Was nun?
      </p>
      <p>
        Meist ist es einfacher etwas zu verstehen, das erlebt oder zumindest angeschaut werden kann.
        Aus diesem Grund bietet dieser Kurs mehr als einen einfachen Frontal-Vortrag. Durch die Mischung meiner
        Kenntnisse mit Ihren Situationen und Interessen können wir den Kursinhalt weitmöglichst Ihren Bedürfnissen anpassen.
        So können Sie Methoden entwickeln, welche Ihnen helfen können, Ihr Aquarium einzurichten. Mit Glück und Geduld und
        dem ein oder anderem Trick kann es sogar gelingen, ein autarkes Becken aufzustellen.
      </p>
      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Vortrag (+Mitmachkurs)
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Tisch pro Teilnehmer + 1 Tisch extra<br/>
        Materialbedarf: keiner
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 1 Tisch
      </p>
      <p>
        + keine besonderen Voraussetzungen nötig +
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w02TA04Barsch
